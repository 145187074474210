import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "bar", "container"];

  toggle() {
    for (let el of this.tabTargets) {
      el.classList.toggle("active-span");
    }

    for (let el of this.barTargets) {
      el.classList.toggle("active-bar");
    }

    for (let el of this.containerTargets) {
      el.classList.toggle("hidden");
    }
  }
}