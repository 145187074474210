// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

import "css/site"
import "controllers/site"
import "../controllers/carousel_controller"
import 'swiper/swiper-bundle.min.css'

// Utility function to require all files in a directory...
function requireAll(r) {
  r.keys().forEach(r);
}

// Require everything in our modules directory
requireAll(require.context("./modules/", true, /\.js$/)); // eslint-disable-line no-undef

// Add images to the manifest
requireAll(require.context("images/", true, /\.(svg|png|jpg|jpeg|gif)$/)); // eslint-disable-line no-undef

Rails.start()
Turbolinks.start()
