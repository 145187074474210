import intlTelInput from 'intl-tel-input';

document.addEventListener("turbolinks:load", function () {
  const input = document.querySelector("#phone");
  const containerError = document.getElementById('error-container');
  const itemError = document.getElementById('error-list-item');

  // var validMsg = document.querySelector("#valid-msg");

  // here, the index maps to the error code returned from getValidationError - see readme
  var errorMap = ["Número inválido", "Código do país inválido", "Número curto demais", "Número longo demais"];

  // const output = document.querySelector("#output");
  if (input != undefined) {
    var iti = intlTelInput(input, {
      initialCountry: "br",
      preferredCountries: ['br', 'us'],
      allowDropdown: true,
      separeDialCode: true,
      nationalMode: true,
      search: true,
      hiddenInput: "phone_number",
      // utilsScript: "../../build/js/utils.js?1613236686837"
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js",
    });

    var handleChange = function () {
    };

    // listen to "keyup", but also "change" to update when the user selects a country
    input.addEventListener('change', handleChange);
    input.addEventListener('keyup', handleChange);


    // Verify if phone is valid
    var reset = function () {
      input.classList.remove("error");
      itemError.innerHTML = "";
      containerError.classList.add("hidden");
      itemError.classList.add("hidden");
    };

    var validate = function () {
      reset();
      if (input.value.trim()) {
        var submit = document.getElementById('submit');
        if (iti.isValidNumber()) {
          input.classList.remove("error");
          submit.disabled = false;
        } else {
          input.classList.add("error");
          var errorCode = iti.getValidationError();
          var message = errorMap[errorCode];
          if (message != undefined) {
            itemError.innerHTML = message;
            itemError.classList.remove("hidden");
            containerError.classList.remove("hidden");
          }
          submit.disabled = true;
        }
      }
    };

    // on blur/focus: validate
    input.addEventListener('focus', validate);
    input.addEventListener('blur', validate);

    // on keyup / change flag: reset
    input.addEventListener('change', reset);
    input.addEventListener('keyup', validate);
  };
});