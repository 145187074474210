document.addEventListener("turbolinks:load", function () {
  const countdownComponents = document.getElementsByClassName('countdown-component');
  
  // const countdownComponent = document.getElementById('countdown-component');
  // const countdownOutput = document.getElementById("countdown-output");

  if (countdownComponents.length > 0) {
    Array.from(countdownComponents).forEach(function(countdownComponent){
      var countdownEndAt = countdownComponent.dataset.countdownEndAt;
      var countdownOutput = document.getElementById(countdownComponent.dataset.countdownOutput);
      // Set the date we're counting down to
      var countDownDate = new Date(countdownEndAt).getTime();

      // Update the count down every 1 second
      var x = setInterval(function () {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        var output = "";
        if (days > 0) { output += days + "d " };
        if (hours > 0) { output += hours + "h " };
        if (minutes > 0 || hours > 0) { output += minutes + "m " };
        if (seconds >= 0) { output += seconds + "s " };

        if (output == "") { output = "0s" };
        
        // Output the result in an element with id="countdown-timer"
        countdownOutput.innerHTML = output;
      }, 1000);
    });
  }
});