import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Carousel from "stimulus-carousel"
// import Clipboard from 'stimulus-clipboard'


const application = Application.start(document.documentElement)
const context = require.context('.', true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.register("carousel", Carousel)
// application.register('clipboard', Clipboard)