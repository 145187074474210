import { Controller } from "stimulus";

export default class extends Controller {
  toggle(event) {
    let target = event.currentTarget.dataset.id;
    let el = document.getElementById(target);
    el.classList.toggle("hidden");

    let icon = event.currentTarget.lastElementChild;
    if (icon) {
      icon.classList.toggle('icon-rotate');
    }
  }
}